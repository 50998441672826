import React, { useState } from 'react'
import { PaperIcon, TagsIcon } from 'Components/Icon'
import { Database2 } from '@styled-icons/remix-line/Database2'

import './index.scss'

const SEGMENT_CARDS = [
  {
    key: 'BLANK',
    title: 'Start with a blank segment',
    desc: 'You will set up custom segment of your own',
    Icon: <PaperIcon size={50} color="rgba(0, 0, 0, 0.25)" />,
  },
  // {
  //   key: 'TEMPLATE',
  //   title: 'Start with a segment template',
  //   desc: 'Choose a preset template and customize as you want',
  //   Icon:  <TagsIcon size={50} color="rgba(0, 0, 0, 0.25)" />
  // },
  {
    key: 'CUSTOM_SQL',
    title: 'Start with SQL',
    desc: 'You will set up custom segment by using SQL',
    Icon: <Database2 style={{ width: 60, color: 'rgba(0, 0, 0, 0.25)' }} />,
  },
]

const NewSegmentNavigation = ({ onSelectScreen }) => {
  return (
    <div className="flex gap-4">
      {SEGMENT_CARDS.map(({ title, desc, Icon, key }) => (
        <div
          onClick={() => onSelectScreen(key)}
          key={key}
          style={{ height: 200, borderRadius: 4 }}
          className="SegmentCard bg-gray_1 segment-card cursor-pointer border border-gray_5 w-1/2 p-5 flex items-center justify-center"
        >
          <div className="px-5" style={{ fontSize: 30 }}>
            {Icon}
          </div>
          <div>
            <span className="font-bold" style={{ fontSize: 18 }}>
              {title}
            </span>
            <p className="m-0">{desc}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default NewSegmentNavigation
